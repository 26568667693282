
import './App.css';

import Navbar from './components/Navbar';
import Body from './components/body';

function App() {
  return (
<div>
   <Navbar/>
   <Body/>
   </div>
  );
}

export default App;