import React from "react";
// import {Link} from 'react-router-dom';

import '../components/Navbar.css'

 import logo from "../logo.png"
const Navbar = () =>{
    return (

        <nav>
             <img src={logo} alt="Logo" className="logo" />
        <ul>
          <li><a className="nav-link" >HOME</a></li>
          <li><a className="nav-link" >ABOUT</a></li>
          <li><a className="nav-link" >COURSES</a></li>
          <li><a className="nav-link" >CONTACT</a></li>
        </ul>
      </nav>
    );
  }

export default Navbar;

