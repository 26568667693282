import React from "react";
import "../components/body.css";
import image1 from "../image.png";
import workshop from "../workshop.png";
import videos from "../video.mp4";
import logo from "../logo.png";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../img1.png";
import img2 from "../img2.png";
import img3 from "../img3.png";
import img4 from "../img4.png";
import img5 from "../img5.png";
import img6 from "../img6.png";
import img7 from "../img7.png";
// import img8 from "../img8.png";

import whatsapp from "../whatsapp.png";



const Body = () => {
  return (
    <div>
      <div className="test">
        <img src={image1} className="image1" alt=""/>

        <h1 className="header">UNLOCK YOUR POTENTIAL WITH HANDS-ON LEARNING</h1>

        <p className="heading">
          VSN EDU mission is to move heaven and earth in order to equip our
          students with the best industrial practice and knowhow
        </p>

        <button className="button">About Us</button>
      </div>
      <div className="test1">
        <h1 className="header1">
          ARE YOU READY TO TAKE YOUR SKILLS TO THE NEXT LEVEL?
        </h1>

        <p className="heading1">
          At VSN EDU, we're dedicated to providing you with the practical
          knowledge and insights you need to succeed in today's competitive
          business world.
        </p>

        <div className="butt">
          <button className="button1">Lets's Watch</button>
        </div>
        <video
          className="video"
          src={videos}
          width="600"
          height="300"
          controls="controls"
          autoPlay={true}
        ></video>
      </div>

      <div className="test2">
        <h1 className="header1">
          CHECK OUT SOME HIGHLIGHTS FROM OUR PAST WORKSHOPS AND EVENTS
        </h1>
        <p className="heading1">
          See the impact of our practical approach and get a glimpse into what
          you can expect from our upcoming workshops.
        </p>
        <div class="image-container">
          <img src={img1} alt="Imag 1" />
          <img src={img2} alt="Imag 2" />
          <img src={img3} alt="Imag 3" />
          {/* <img src={img8} alt="Imag 8" /> */}
          <img src={img4} alt="Imag 4" />
          <img src={img5} alt="Imag 5" />
          <img src={img6} alt="Imag 6" />
          <img src={img7} alt="Imag 7" />
        </div>
      </div>
      <img
        src={workshop}
        alt=""
        className="workshop"
      />
      <div className="test3">
        <h1 className="header2">WORKSHOPS ON BEING INDUSTRY READY</h1>

        <p className="heading2">
          Join us to gain practical knowledge and insights, and take your skills
          in these key areas to the next level.
        </p>
        <p className="heading2">
          Our workshops cover a wide range of essential topics and skills,
          including financial analysis, start-up creation, GST and Income Tax
          laws, practical case studies, tool proficiency, and business
          operations.
        </p>
      </div>
      <div className="but">
        <button className="button1">Know More</button>
      </div>

      <div className="testimonial">
        <h1 className="header3">GET IN TOUCH</h1>
        {/* <h2 className="header4">Let us know how we can help</h2> */}
        <p className="para">
          Do you have any questions or queries? The members of our expert team
          is always happy to help you
        </p>
        <div className="buts">
          <a
            href="https://api.whatsapp.com/send?phone=9899783871&text=Hello%20there!"
            className="hyper"
          >
            <button className="button3">
              <img src={whatsapp} alt="whatsapp" className="whatsapp" />
              Chat With Us
            </button>
          </a>
        </div>
      </div>
      <div className="footerBox">
        <div className="footerBox1">
          <img src={logo} className="logo1" alt ="" />
          <div className="company">
            <h3>COMPANY</h3>
            <p>How it works</p>
            <p>Pricing</p>
            <p>Demo</p>
          </div>

          <div className="resources">
            <h3>RESOURCES</h3>
            <p>Blog post name goes here</p>
            <p>Blog post name goes here</p>
            <p>Blog post name goes here</p>
            <p>See all resources</p>
          </div>
          <div className="about">
            <h3>ABOUT</h3>
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
        <div className="footer">
          <p>Copyright © 2022 Company name</p>
        </div>
      </div>
    </div>
  );
};

export default Body;
